import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Modal, Row } from "react-bootstrap"
import ExcerptQuote from "../assets/images/blog-quote.png"
import Calendar from "../assets/images/calendar-schedule-checkmark.svg"
import Clock from "../assets/images/clock-time-checkmark.svg"
import FormBuilder from "../components/common/FormBuilder/Form-Builder"
import handleFormSubmit from "../components/common/FormBuilder/Form-Function"
import Frame from "../components/common/frame"
import PopupFormBuilder from "../components/common/popupFormBuilder"
import SVGIcon from "../components/common/SVGIcon"
import Navigation from "../components/navigation"
import PulsatingRing from "../components/utility/pulsating_ring"
import Slide from "../components/utility/slide_content"
import FeaturedTopics from "../components/webinar/featured-topics"
import WebinarCard from "../components/webinar/webinar-card"
import "../styles/pages/webinar.scss"
import Close from "./../assets/images/close-white.png"

function Webinar({ pageContext, location }) {
  const { webinar } = pageContext
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "First Name",
      parentClassName: "input-50",
      value: "",
      errorMessage: "Please enter your first name",
    },
    {
      name: "lastname",
      type: "text",
      label: "Last Name",
      parentClassName: "input-50",
      value: "",
      errorMessage: "Please enter your last name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      value: "",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      required: false,
      errorMessage: "Please enter a mobile number",
    },
    {
      name: "webinar_name",
      type: "hidden",
      label: "Webinar name",
      value: webinar.heroHeading,
      required: false,
    },
    {
      name: "webinar_registration_type",
      type: "hidden",
      label: "Registration type",
      value: new Date(webinar.date) >= new Date() ? "Live" : "On-Demand",
      required: false,
    },
  ]

  const [pageURL, setPageURL] = useState(0)
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [sameWebinar, setSameWebinar] = useState("new")
  const [ondemand, setOndemand] = useState(webinar.videoLink ? true : false)
  const [show, setShowVideo] = useState(false)

  useEffect(() => {
    setPageURL(window.location.href)
    const allWebinars = JSON.parse(
      localStorage.getItem("on_demand_webinars_registered")
    )
    if (allWebinars) {
      if (allWebinars.includes(webinar.heroHeading)) {
        setSameWebinar("same")
      } else {
        setSameWebinar("different")
      }
    }
  }, [])

  const togglePopup = () => {
    if (sameWebinar === "same") {
      handleShow()
    } else if (sameWebinar === "different") {
      handleShow()
      internalSubmit(
        JSON.parse(localStorage.getItem("on_demand_user_form_inputs"))
      )
    } else {
      setPopupVisibility(!popupVisibility)
    }
  }

  const handleClose = () => {
    setShowVideo(false)
  }
  const handleShow = () => {
    setShowVideo(true)
  }

  const addWebinarNameList = () => {
    let onDemandWebinars = JSON.parse(
      localStorage.getItem("on_demand_webinars_registered")
    )
    if (onDemandWebinars) {
      onDemandWebinars.push(webinar.heroHeading)
    } else {
      onDemandWebinars = [webinar.heroHeading]
    }
    localStorage.setItem(
      "on_demand_webinars_registered",
      JSON.stringify(onDemandWebinars)
    )
  }

  const handleAfterSubmit = (response, items) => {
    localStorage.setItem("on_demand_user_form_inputs", JSON.stringify(items))
    addWebinarNameList()
    setSameWebinar("same")
    setPopupVisibility(!popupVisibility)
    setShowVideo(true)
  }

  const internalSubmit = data => {
    let final_data = [...data]
    final_data = final_data.filter(el => el.name !== "webinar_name")
    final_data.push({
      name: "webinar_name",
      value: webinar.heroHeading,
    })
    if (webinar.outboundSource) {
      final_data.push({
        name: "outbound_source",
        value: webinar.outboundSource,
      })
    }
    handleFormSubmit(
      final_data,
      process.env.HUBSPOT_WEBINAR_REGISTER_API_ENDPOINT,
      document.URL,
      `Webinar - Superops`
    )
    setSameWebinar("same")
    addWebinarNameList()
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentWebinarTemplate {
            SuperOps {
              pages(where: { title: "Resource" }) {
                title
                pageBlock {
                  id
                  content {
                    html
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="webinar">
            {data.SuperOps.pages.map(page => {
              const { title, navigationBlock } = page

              return (
                <>
                  <Frame seo={webinar.seo}>
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page={title}
                        darkHelloBar
                        theme="dark"
                        darkTheme
                        btn1="secondary white"
                        btn2="primary white ml-9"
                        newButton
                        // color={
                        //   webinar.heroBgColor ? webinar.heroBgColor : "#fff2f1"
                        // }
                      />
                    </header>

                    <div>
                      <PopupFormBuilder
                        visibility={popupVisibility}
                        togglePopup={() => setPopupVisibility(!popupVisibility)}
                        formdata={formdata}
                        handleAfterSubmit={handleAfterSubmit}
                        pageURL={pageURL}
                      />
                    </div>

                    <section
                      className="hero"
                      style={{
                        background: "#000",
                      }}
                      // style={{
                      //   background: webinar.heroBgColor
                      //     ? webinar.heroBgColor
                      //     : "#fff2f1",
                      // }}
                    >
                      <Container>
                        <Row>
                          <Col lg={6}>
                            <div className="breadcrumb p14 down-to-up-1">
                              <p className="active text-white"> webinar</p>
                            </div>
                            <h1 className="down-to-up-1 heading text-white">
                              {" "}
                              {webinar.heroHeading}
                            </h1>
                            <div className="p16 down-to-up-2 description">
                              <p className="text-white">
                                {webinar.heroSubheading}
                              </p>
                            </div>
                            {(webinar.speakerName || webinar.hostName) && (
                              <div className="details grid-container down-to-up-3">
                                {webinar.speakers.map(speaker => (
                                  <div className="detail grid-lg-2 grid-md-2 grid-sm-2">
                                    <img
                                      alt="Img"
                                      src={speaker?.image[0]?.url}
                                    />
                                    <div className="p12">
                                      <p className="mb-2">
                                        {speaker.cardName
                                          ? speaker.cardName
                                          : "speaker"}
                                      </p>
                                    </div>
                                    <div className="p16 text-white">
                                      <p className="text-white">
                                        {speaker?.title[0]}
                                      </p>
                                    </div>
                                    {speaker?.text[0] && (
                                      <p className="p14 text-light-grey">
                                        {speaker?.text[0]}
                                      </p>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </Col>
                          <Col lg={6}>
                            {!ondemand ? (
                              <div className="info-single down-to-up-1">
                                <div className="grid-container">
                                  <div className="grid-lg-2 grid-md-2 grid-sm-2">
                                    <img src={Calendar} alt="img" />
                                    <div className="p18">
                                      <p>{webinar.date}</p>
                                    </div>
                                  </div>
                                  <div className="grid-lg-2 grid-md-2 grid-sm-2">
                                    <img src={Clock} alt="img" />
                                    <div className="p18">
                                      <p>{webinar.time}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {ondemand ? (
                              <>
                                <div
                                  className={`text-center m-0 video-player down-to-up-1 prelative mb50`}
                                  onClick={() => {
                                    togglePopup()
                                  }}
                                >
                                  <img
                                    src={webinar.coverImage.url}
                                    alt="image"
                                    className={`hero-image video-link`}
                                    style={{
                                      maxWidth: "100%",
                                    }}
                                  />
                                  <PulsatingRing />
                                  <SVGIcon
                                    name="home/play-button"
                                    className="position-absolute play"
                                  />
                                </div>
                                <Modal
                                  className="yt"
                                  show={show}
                                  onHide={() => setShowVideo(false)}
                                  centered
                                  dialogClassName="modal-80w"
                                  aria-labelledby="example-custom-modal-styling-title"
                                >
                                  <img
                                    src={Close}
                                    alt="close"
                                    className="close-icn"
                                    onClick={handleClose}
                                    decoding="async"
                                  />
                                  <Container className="yt-vdo">
                                    <iframe
                                      className="video"
                                      src={`${webinar.videoLink}?rel=0&autoplay=1`}
                                      frameborder="0"
                                      allowfullscreen="allowfullscreen"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    ></iframe>
                                  </Container>
                                </Modal>
                              </>
                            ) : (
                              <div className="grid-container subscribe down-to-up-2">
                                <Container className="forms cn-cta">
                                  <FormBuilder
                                    id="webinar-form"
                                    formClassName="row"
                                    errorClassName="error-message"
                                    inputClassName="input-sm"
                                    inputErrorClassName="error"
                                    buttonText="REGISTER NOW"
                                    buttonClassName="primary"
                                    data={formdata}
                                    IpStackData
                                    formName="Webinar - Superops"
                                    extradata={{
                                      name: "outbound_source",
                                      value: webinar.outboundSource,
                                    }}
                                    endpoint={
                                      process.env
                                        .HUBSPOT_WEBINAR_REGISTER_API_ENDPOINT
                                    }
                                    url={pageURL}
                                    path="resources"
                                  >
                                    <div className="p12">
                                      <p>
                                        By clicking "REGISTER NOW", you agree to
                                        SuperOps' <br />
                                        <a className="tdnone" href="/privacy">
                                          Terms of use
                                        </a>{" "}
                                        and{" "}
                                        <a className="tdnone" href="/privacy">
                                          Privacy Policy.
                                        </a>
                                      </p>
                                    </div>
                                  </FormBuilder>
                                </Container>
                              </div>
                            )}

                            <div className="excerpt down-to-up-4">
                              <img src={ExcerptQuote} alt="img" />
                              <span className="p18 text-white">
                                <p>{webinar.quote}</p>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section>

                    <section className="cover">
                      <Container>{/*  */}</Container>
                    </section>

                    <section className="content">
                      <Container>
                        <Slide style={{ "--sal-delay": "0.2s" }}>
                          <div className="intro p16">
                            <p>{parse(webinar.block1.html)}</p>
                          </div>
                        </Slide>
                      </Container>
                    </section>

                    <section className="featured-topics">
                      <Container>
                        {/* <Row>
                        <Col lg={12} className="position-relative"> */}
                        <Slide style={{ "--sal-delay": "0.2s" }}>
                          <FeaturedTopics
                            FeaturedTopics={webinar.webinarOutcomes}
                            FeaturedTopicsBg={webinar.outcomesBackgroundColor}
                          />
                        </Slide>
                        {/* </Col>
                      </Row> */}
                      </Container>
                    </section>

                    {(webinar.speakerName || webinar.hostName) && (
                      <section className="about">
                        <Container>
                          <Row>
                            <Col lg={4}>
                              <div className="grid-container">
                                <div className="altv3">
                                  <h1 className="fw-bold">
                                    But, who <br /> are we?
                                  </h1>
                                </div>

                                {webinar.block2Heading && (
                                  <div>
                                    <WebinarCard
                                      title={webinar.block2Heading}
                                      excerpt={webinar.block2Content}
                                      slug={"/"}
                                      cardSize="small"
                                      contentMinHeight="360px"
                                      background={"#F6FAFF"}
                                      style={{ marginBottom: "24px" }}
                                    />
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col
                              lg={8}
                              className="d-flex flex-wrap justify-content-between"
                            >
                              {webinar.speakers.map(speaker => (
                                // <Col lg={6}>
                                <WebinarCard
                                  title={speaker?.title[0]}
                                  subtitle={speaker?.text[0]}
                                  excerpt={speaker?.subtext[0]}
                                  coverImage={speaker?.image[0]}
                                  coverImageHeight={"215px"}
                                  tags={[
                                    speaker.cardName
                                      ? speaker.cardName
                                      : "speaker",
                                  ]}
                                  slug={"/"}
                                  cardSize="med"
                                  contentMinHeight="360px"
                                  style={{ marginBottom: "24px" }}
                                />
                                // </Col>
                              ))}
                            </Col>
                          </Row>
                        </Container>
                      </section>
                    )}
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default Webinar
